import React from 'react';
import {
  ApiTypesV1GroupDetails,
  ApiTypesV1GroupSettings,
} from '@wix/social-groups-api';
import { GroupActionsConsumer } from './GroupActionsContext';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';

export interface GroupUpdate {
  updateGroup(
    paths: string[],
    details?: ApiTypesV1GroupDetails,
    settings?: ApiTypesV1GroupSettings,
  ): void;
  setEditMode(mode: UpdateProgress): void;
}

export interface UpdateContext {
  updateProgress: UpdateProgress;
}

export const withGroupUpdate =
  <P extends GroupUpdate>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<Omit<P, keyof GroupUpdate>> =>
  (props) =>
    (
      <GroupActionsConsumer>
        {({ updateGroup, setEditMode }) => (
          <WrappedComponent
            {...(props as P)}
            updateGroup={updateGroup}
            setEditMode={setEditMode}
          />
        )}
      </GroupActionsConsumer>
    );
